import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Option from "./Option";
import OptionElement from "./OptionElement";
import Options from "./Options";
import OptionsStepContainer from "./OptionsStepContainer";
import { Input, SubmitButton } from "../../components";
import { SubmitIcon } from "../../icons";
import OptionFreeWriting from "./OptionFreeWriting";

class OptionsStep extends Component {
  state = {
    showFreeWriting: false,
    freeWritingValue: "",
  };

  submitFreeWriting = () => {
    const { step, triggerNextStep } = this.props;
    const { freeWritingValue } = this.state;
    const option = step.options.find((option) => option.isFreeWriting);

    if (!freeWritingValue) return;

    triggerNextStep({
      value: option.value,
      freeWritingValue,
    });
  };

  onOptionClick = (option) => () => {
    if (option.isWithLink && option.link) {
      window.open(option.link, "_BLANK");
    }

    const { triggerNextStep } = this.props;
    triggerNextStep({ value: option.value });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.submitFreeWriting();
    }
  };

  renderOption = (option) => {
    const { bubbleOptionStyle, step } = this.props;
    const { showFreeWriting } = this.state;
    const { user } = step;
    const { value, label, link, isWithLink } = option;

    return (
      <Option key={value} className="rsc-os-option">
        <OptionElement
          className="rsc-os-option-element"
          style={bubbleOptionStyle}
          user={user}
          showUnderline={link && isWithLink}
          showFreeWriting={showFreeWriting}
          onClick={this.onOptionClick(option)}
        >
          {label}
        </OptionElement>
      </Option>
    );
  };

  renderFreeWritingOption = (option) => {
    const { showFreeWriting, freeWritingValue } = this.state;

    if (!option?.enableFreeWriting) {
      return null;
    }

    return (
      <Fragment key="rsc-os-option">
        {!showFreeWriting && (
          <Option className="rsc-os-option">
            <OptionElement
              className="rsc-os-option-element"
              onClick={() => this.setState({ showFreeWriting: true })}
            >
              Other (Free Writing)
            </OptionElement>
          </Option>
        )}
        {showFreeWriting && (
          <>
            <OptionFreeWriting>
              <Input
                type="textarea"
                className="rsc-os-input"
                autoFocus
                placeholder="Type something..."
                onKeyPress={this.handleKeyPress}
                onChange={(event) =>
                  this.setState({ freeWritingValue: event.target.value })
                }
                value={freeWritingValue}
                hasButton
              />
              <SubmitButton
                className="rsc-os-input-btn"
                onClick={this.submitFreeWriting}
              >
                <SubmitIcon />
              </SubmitButton>
            </OptionFreeWriting>
          </>
        )}
      </Fragment>
    );
  };

  render() {
    const { step } = this.props;
    const { options } = step;
    const simpleOptions = options.filter((option) => !option.isFreeWriting);
    const freeWritingOption = options.find((option) => option.isFreeWriting);

    return (
      <OptionsStepContainer className="rsc-os">
        <p>Select option:</p>
        <Options className="rsc-os-options">
          {simpleOptions.map(this.renderOption)}
          {this.renderFreeWritingOption(freeWritingOption)}
        </Options>
      </OptionsStepContainer>
    );
  }
}

OptionsStep.propTypes = {
  bubbleOptionStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  triggerNextStep: PropTypes.func.isRequired,
};

export default OptionsStep;
