import styled from "styled-components";

const InputIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  display: flex;
  & > svg {
    width: 20px;
    height: 20px;
    path {
      fill: gray;
    }
  }
`;

export default InputIconWrapper;
