import { createContext, useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import { useMessageEventListener } from "hooks";
import { formatStepsFromBuilderToWidget } from "utils/helperFunctions";

dayjs.extend(dayjsUtc);

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common["Content-Type"] = "application/json";

const Context = createContext({});
const { Provider } = Context;

const AppProvider = function ({ children }) {
  const enquiries = useRef([]);
  const sentIframeLoadedEvent = useRef(false);

  // State
  const [loading, setLoading] = useState(false);
  const [isPreview, setPreview] = useState(false);
  const [enquirySession, setEnquirySession] = useState(null);
  const [steps, setSteps] = useState([]);
  const [originalNodesMap, setOriginalNodesMap] = useState(null);
  const [isMobileMode, setIsMobileMode] = useState(false);

  // Functions
  /* START - Enquiries */
  const handleRequestFail = useCallback((error) => {
    const errorMessage =
      error.response?.data?.message || error?.response?.data?.error;

    if (
      errorMessage &&
      errorMessage.toLowerCase().includes("unauthenticated")
    ) {
      // TODO - Request token again and refetch all the request
      setLoading(true);
    }

    if (error?.message) {
      return error?.message;
    }

    return "Oops! There was a problem";
  }, []);

  const updateOrCreateEnquirySession = useCallback(
    async (isEnded = false) => {
      if (isPreview) return { error: null };

      const body = {
        data: enquiries.current,
        whitelist_url: window.location.origin,
        enquiry_request_completed_at: isEnded
          ? dayjs.utc().format("YYYY-MM-DD HH:mm:ss")
          : null,
      };

      try {
        const request = enquirySession
          ? axios.put(`/tapnotes/enquiry/${enquirySession.id}`, body)
          : axios.post("/tapnotes/enquiry", body);
        const response = await request;
        setEnquirySession(response.data);
        return { error: null };
      } catch (error) {
        return { error: handleRequestFail(error) };
      }
    },
    [isPreview, enquirySession, handleRequestFail]
  );

  const addEnquiry = useCallback(
    (enquiry) => {
      enquiries.current = [...enquiries.current, enquiry];
      return updateOrCreateEnquirySession();
    },
    [updateOrCreateEnquirySession]
  );

  const handleRestoreEnquiriesToStepId = useCallback(
    async (stepId) => {
      const enquiryIndex = enquiries.current.findIndex(
        (enquiry) => enquiry.id === stepId
      );
      enquiries.current = enquiries.current.slice(0, enquiryIndex);
      return updateOrCreateEnquirySession();
    },
    [updateOrCreateEnquirySession]
  );

  const handlePartiallyEnded = useCallback(() => {
    return updateOrCreateEnquirySession(true);
  }, [updateOrCreateEnquirySession]);

  const handleEndEnquiry = useCallback(() => {
    enquiries.current = [];
    setEnquirySession(null);
  }, []);
  /* END - Enquiries */

  const fetchChatbotData = useCallback(async () => {
    try {
      const response = await axios.get(
        `/tapnotes/chatbot?whitelist_url=${window.location.origin}`
      );
      const { nodes, edges } = response.data;

      formatAndSetChatbotSteps();
      createOriginalNodesMap();

      return { error: null };

      function formatAndSetChatbotSteps() {
        const stepsData = formatStepsFromBuilderToWidget({ nodes, edges });
        setSteps(stepsData);
      }

      function createOriginalNodesMap() {
        const nodesMap = new Map();
        nodes.forEach((node) => {
          nodesMap.set(node.id, node);
        });
        setOriginalNodesMap(nodesMap);
      }
    } catch (error) {
      return { error: handleRequestFail(error) };
    }
  }, [handleRequestFail]);

  /* START - Message Event Listener */
  const handleGettedToken = useCallback(
    (data) => {
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      fetchChatbotData();
    },
    [fetchChatbotData]
  );

  const handleGettedSettings = useCallback((data) => {
    if (data.api) {
      axios.defaults.headers.common["X-API-KEY"] = data.api;
    }

    if (data.preview) {
      const stepsData = formatStepsFromBuilderToWidget(data.preview);
      setSteps(stepsData);
      setPreview(true);

      createOriginalNodesMap();

      function createOriginalNodesMap() {
        const nodesMap = new Map();
        data.preview.nodes.forEach((node) => {
          nodesMap.set(node.id, node);
        });
        setOriginalNodesMap(nodesMap);
      }
    }
  }, []);

  const handleGettedParentWindowDim = useCallback((data) => {
    setIsMobileMode(data.width <= 600);
  }, []);

  const handleNewMessage = useCallback(
    ({ type, payload }) => {
      switch (type) {
        case "token":
          return handleGettedToken(payload);
        case "tapnote_settings":
          return handleGettedSettings(payload);
        case "window_dimensions":
          return handleGettedParentWindowDim(payload);
        default:
          break;
      }
    },
    [handleGettedToken, handleGettedSettings, handleGettedParentWindowDim]
  );

  useMessageEventListener(handleNewMessage);
  /* END - Message Event Listener */

  // Effects
  useEffect(() => {
    if (!sentIframeLoadedEvent.current) {
      sentIframeLoadedEvent.current = true;
      window.parent.postMessage({ typeMessage: "iframe_loaded" }, "*");
    }
  }, []);

  // Render
  return (
    <Provider
      value={{
        loading,
        steps,
        isMobileMode,
        enquiries,
        originalNodesMap,
        isPreview,
        addEnquiry,
        onEndEnquiry: handleEndEnquiry,
        onPartiallyEnded: handlePartiallyEnded,
        onRestoreEnquiriesToStepId: handleRestoreEnquiriesToStepId,
      }}
    >
      {children}
    </Provider>
  );
};

export default AppProvider;
export { Provider, Context };
