import React, { Component } from "react";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import Image from "./Image";
import ImageContainer from "./ImageContainer";
import Loading from "../common/Loading";
import TextStepContainer from "./TextStepContainer";
import ChangeButton from "./ChangeButton";
import { replaceUrlWithAnchor } from "../../utils";

class TextStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.timer = null;
    this.delay = props.step.user ? 0 : props.step.delay;
  }

  componentDidMount() {
    const { step } = this.props;
    const { pendingRequest } = step;

    if (pendingRequest) {
      this.executeStepPendingRequest();
    }

    this.timer = setTimeout(() => {
      this.finishLoading();
      this.clearTimer();
    }, this.delay);
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  executeStepPendingRequest = async () => {
    const { step } = this.props;
    const { pendingRequest } = step;

    try {
      await pendingRequest();
    } catch (error) {
      console.log("Error executing pendingRequest", error);
    }
  };

  finishLoading = () => {
    const { step, speak, previousValue, triggerNextStep } = this.props;
    const { component, waitAction } = step;
    const isComponentWatingUser = component && waitAction;
    this.setState({ loading: false }, () => {
      if (!isComponentWatingUser && !step.rendered) {
        triggerNextStep();
      }
      speak(step, previousValue);
    });
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  getMessage = () => {
    const { previousValue, step } = this.props;
    let { message } = step;

    message = message ? message.replace(/{previousValue}/g, previousValue) : "";
    message = replaceUrlWithAnchor(message);
    message = parse(message);

    return message;
  };

  renderMessage = () => {
    const { step, steps, previousStep, triggerNextStep } = this.props;
    const { component } = step;

    if (component) {
      return React.cloneElement(component, {
        step,
        steps,
        previousStep,
        triggerNextStep,
      });
    }

    return this.getMessage();
  };

  render() {
    const {
      step,
      isFirst,
      isLast,
      avatarStyle,
      bubbleStyle,
      hideBotAvatar,
      hideUserAvatar,
      goBackToStep,
    } = this.props;
    const { loading } = this.state;
    const { id, avatar, user, botName } = step;

    const showAvatar = isFirst && (user ? !hideUserAvatar : !hideBotAvatar);

    const imageAltText = user ? "Your avatar" : `${botName}'s avatar`;

    const handleGoBackToStep = () => {
      goBackToStep(step);
    };

    return (
      <TextStepContainer
        id={`chatbot-step-${id}`}
        className={`rsc-ts ${user ? "rsc-ts-user" : "rsc-ts-bot"}`}
        user={user}
        isLast={isLast}
        isFirst={isFirst}
        showAvatar={showAvatar}
      >
        {showAvatar && (
          <ImageContainer className="rsc-ts-image-container" user={user}>
            <Image
              className="rsc-ts-image"
              style={avatarStyle}
              showAvatar={showAvatar}
              user={user}
              src={avatar}
              alt={imageAltText}
            />
          </ImageContainer>
        )}
        <Bubble
          className="rsc-ts-bubble"
          style={bubbleStyle}
          user={user}
          showAvatar={showAvatar}
          isFirst={isFirst}
          isLast={isLast}
        >
          {!user && loading ? <Loading /> : this.renderMessage()}
        </Bubble>
        {user && (
          <ChangeButton disabled={loading} onClick={handleGoBackToStep}>
            Change
          </ChangeButton>
        )}
      </TextStepContainer>
    );
  }
}

TextStep.propTypes = {
  avatarStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  bubbleStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  hideBotAvatar: PropTypes.bool.isRequired,
  hideUserAvatar: PropTypes.bool.isRequired,
  previousStep: PropTypes.objectOf(PropTypes.any),
  previousValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  speak: PropTypes.func,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  steps: PropTypes.objectOf(PropTypes.any),
  triggerNextStep: PropTypes.func.isRequired,
};

TextStep.defaultProps = {
  previousStep: {},
  previousValue: "",
  speak: () => {},
  steps: {},
};

export default TextStep;
