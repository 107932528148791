import { useCallback, useEffect, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "../Chatbot/ChatBot";
import BubbleIcon from "./BubbleIcon";
import chatbotTheme from "./chatbotTheme";
import { useAppStore } from "context";
import { ENQUIRY_TYPES, NODE_TYPES_NAME } from "const";

const { OPTIONS_STEP, ENTRY_FIELD_STEP } = NODE_TYPES_NAME;
const isInAnIframe = window.location !== window.parent.location;

const AUTO_OPEN_TIME = 3; // in seconds
const PREVIEW_AUTO_OPEN_TIME = 0; // in seconds

const ChatbotWrapper = () => {
  // Top level hooks
  const {
    steps,
    isPreview,
    isMobileMode,
    originalNodesMap,
    addEnquiry,
    onEndEnquiry,
    onPartiallyEnded,
    onRestoreEnquiriesToStepId,
  } = useAppStore();

  // Hooks
  const isEnded = useRef(false);
  const autoOpenTimer = useRef(null);
  const changeKeyTimer = useRef(null);
  const [opened, setOpened] = useState(false);
  const [chatbotKey, setChabotKey] = useState(1);

  // Functions
  const handleBeforeTriggerNextStep = useCallback(
    ({ chatCurrStep = {}, chatPrevStep = {} } = {}) => {
      const currStep = originalNodesMap.get(chatCurrStep.id);
      const prevStep = originalNodesMap.get(chatPrevStep.id);

      if (
        chatCurrStep.user &&
        [OPTIONS_STEP, ENTRY_FIELD_STEP].includes(currStep.type)
      ) {
        return async () => {
          const enquiry = {
            id: chatCurrStep.id,
            question: prevStep?.label || "",
            response: chatCurrStep?.message,
            type: ENQUIRY_TYPES[currStep.type][
              currStep.optionsType || currStep.inputType
            ],
            created_at: new Date().toISOString(),
          };
          return await addEnquiry(enquiry);
        };
      }

      return null;
    },
    [originalNodesMap, addEnquiry]
  );

  const handleToggleOpened = ({ opened }) => {
    setOpened(opened);
    if (opened) {
      isEnded.current = false;
    } else if (isEnded.current) {
      onEndEnquiry();
      changeKeyTimer.current = setTimeout(() => {
        setChabotKey((prev) => (isEnded.current ? prev + 1 : prev));
      }, 300);
    }
  };

  const handleEnd = () => {
    isEnded.current = true;
    onPartiallyEnded();
  };

  // Effects
  useEffect(() => {
    if (!isMobileMode) {
      autoOpenTimer.current = setTimeout(() => {
        setOpened(true);
      }, (isPreview ? PREVIEW_AUTO_OPEN_TIME : AUTO_OPEN_TIME) * 1000);
    }

    return () => {
      clearTimeout(changeKeyTimer.current);
      clearTimeout(autoOpenTimer.current);
    };
  }, [isPreview, isMobileMode]);

  // Render
  return (
    <ThemeProvider theme={chatbotTheme}>
      {isInAnIframe && steps.length > 0 && (
        <ChatBot
          key={chatbotKey}
          width={isMobileMode ? "calc(100% - 40px)" : "350px"}
          height={isMobileMode ? "calc(100% - 122px)" : "calc(100% - 120px)"}
          steps={steps}
          opened={opened}
          hideHeader
          hideUserAvatar
          mobile={isMobileMode}
          floating
          hideBotAvatar
          enableSmoothScroll
          enableMobileAutoFocus
          floatingIcon={<BubbleIcon />}
          placeholder="Type your reply"
          controlStyle={{
            position: "absolute",
            right: "0px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          contentStyle={{
            padding: "18px 8px",
          }}
          beforeTriggerNextStep={handleBeforeTriggerNextStep}
          toggleFloating={handleToggleOpened}
          handleEnd={handleEnd}
          onRestoreStepsTo={onRestoreEnquiriesToStepId}
        />
      )}
      {!isInAnIframe && (
        <p>
          This page is intended to be called inside of a html {"<iframe />"}{" "}
          tag, and it should be used according to tapnote product instructions.
        </p>
      )}
    </ThemeProvider>
  );
};

export default ChatbotWrapper;
