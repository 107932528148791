import styled from "styled-components";

const TextStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.user ? "flex-end" : "flex-start")};
  justify-content: flex-start;
  padding: ${(props) =>
    props.showAvatar
      ? ""
      : props.user
      ? "0px 12px 0px 0px"
      : "0px 0px 0px 12px"};
  margin: ${(props) => (props.isLast ? "0px 0px 10px 0px" : "0px")};
`;

export default TextStepContainer;
