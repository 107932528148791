import styled from 'styled-components';

const Content = styled.div`
  height: auto;
  margin-top: 2px;
  padding-top: 6px;
  box-sizing: border-box;
`;

export default Content;
