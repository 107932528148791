import styled from 'styled-components';
import defaultTheme from '../../theme';

const OptionFreeWriting = styled.div`
  margin-top: 10px;
  position: relative;
  
  & > button {
    position: absolute;
    right: 0px;
    bottom: 3px;
  }
`;

OptionFreeWriting.defaultProps = {
  theme: defaultTheme
};

export default OptionFreeWriting;
