export const isMobile = () =>
  /iphone|ipod|android|ie|blackberry|fennec/i.test(navigator.userAgent);

export const isString = (value) => typeof value === "string";

export const replaceUrlWithAnchor = (value) => {
  let replacedText = value || "";

  // URLs starting with http://, https://, or ftp://
  const replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = replacedText.replace(
    replacePattern1,
    '<a href="$1" target="_BLANK">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_BLANK">$2</a>'
  );

  return replacedText;
};
