import React from "react";

const BubbleIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 45 49"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0.67871 8.521c0-4.3156 3.4984-7.814 7.814-7.814h20.47c4.3156 0 7.814 3.4984 7.814 7.814v13.205c0 4.3156-3.4984 7.814-7.814 7.814h-21.918l-4.4644 4.425c-0.70464 0.6984-1.9021 0.1993-1.9021-0.7928v-24.651z"
      fill="#fff"
    />
    <path
      d="m8.8984 33.596v1.9155c0 4.3155 3.4985 7.814 7.814 7.814h21.918l4.4644 4.425c0.7047 0.6984 1.9021 0.1993 1.9021-0.7929v-24.651c0-3.1155-1.8233-5.8052-4.4611-7.0601v8.3496c0 5.5229-4.4772 10-10 10h-21.637z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default BubbleIcon;
