import styled from 'styled-components';

const Options = styled.ul`
  margin: 2px 0 12px 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;

export default Options;
