import styled from 'styled-components';
import defaultTheme from '../../theme';

const OptionElement = styled.button`
  width: 100%;
  color: ${(props) => props.theme.optionColor};
  background-color: ${(props) => props.theme.optionBgColor};
  padding-left: 20px;
  font-weight: 400;
  border: 0px;
  opacity: ${(props) => props.showFreeWriting ? 0.5 : 1};
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-size: 14px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: ${(props) => props.showUnderline ? "underline" : "none"};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
  &:active,
  &:hover:focus {
    outline:none;
  }
`;

OptionElement.defaultProps = {
  theme: defaultTheme
};

export default OptionElement;
