import { ReactComponent as PhoneCallSvg } from "assets/svg/phoneCall.svg";
import { ReactComponent as EmailedSvg } from "assets/svg/mail.svg";
import { INPUT_TYPES, NODE_TYPES_NAME } from "const";

const { TEXT_STEP, OPTIONS_STEP, ENTRY_FIELD_STEP } = NODE_TYPES_NAME;

export const formatStepsFromBuilderToWidget = ({ nodes, edges }) => {
  const steps = [];

  nodes.forEach((node) => {
    const newNode = {
      id: node.id,
    };
    if (node.type === TEXT_STEP) {
      newNode.message = node.label || "Empty message";
    }
    if (node.type !== OPTIONS_STEP) {
      newNode.trigger = getTarget(node.id);
      newNode.end = !newNode.trigger;
    }
    if (node.type === ENTRY_FIELD_STEP) {
      newNode.user = true;

      switch (node.inputType) {
        case INPUT_TYPES.CONTACT_NUMBER:
          newNode.inputAttributes = {
            type: "tel",
            autoComplete: "tel",
            placeholder: "Type your phone number",
          };
          newNode.icon = <PhoneCallSvg />;
          break;
        case INPUT_TYPES.EMAIL:
          newNode.inputAttributes = {
            type: "email",
            autoComplete: "email",
            placeholder: "Type your email",
          };
          newNode.icon = <EmailedSvg />;
          break;
        case INPUT_TYPES.FULL_NAME:
          newNode.inputAttributes = {
            type: "text",
            autoComplete: "name",
            placeholder: "Type your full name",
          };
          break;
        default:
          newNode.inputAttributes = {
            type: "text",
            autoComplete: "off",
          };
          break;
      }
    }
    if (node.type === OPTIONS_STEP) {
      newNode.options = node.options.map((option) => ({
        value: option.id,
        label: option.label || "",
        isFreeWriting: option.isFreeWriting,
        enableFreeWriting: option.enableFreeWriting,
        isWithLink: option.isWithLink,
        link: option.link,
        trigger: getTarget(node.id, option.id),
      }));
    }
    steps.push(newNode);
  });

  return steps;

  function getTarget(nodeId, sourceHandle) {
    const edge = edges.find(
      (edge) => edge.source === nodeId && edge.sourceHandle === sourceHandle
    );

    return edge?.target;
  }
};
