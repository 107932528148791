const BLUE = "#4755E7";

const chatbotTheme = {
  background: "#fff",
  fontFamily: "Helvetica Neue",
  headerBgColor: BLUE,
  headerFontColor: "#fff",
  headerFontSize: "16px",
  botBubbleColor: "#D0D0D0",
  botFontColor: "#000",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
  userBorder: "1px solid #ccc",
  inputBorder: `3px solid ${BLUE}`,
  submitButtonColor: BLUE,
  optionColor: "#fff",
  optionBgColor: BLUE,
};

export default chatbotTheme;
