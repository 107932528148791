import { invalidInput } from "../common/animations";
import styled, { css } from "styled-components";
import defaultTheme from "../theme";

const Input = styled.input`
  animation: ${(props) =>
    props.invalid
      ? css`
          ${invalidInput} .2s ease
        `
      : ""};
  border: ${(props) => props.theme.inputBorder};
  border-radius: 30px;
  overflow: hidden;
  box-shadow: ${(props) => (props.invalid ? "inset 0 0 2px #E53935" : "none")};
  box-sizing: border-box;
  color: ${(props) => (props.invalid ? "#E53935" : "")};
  font-size: 14px;
  opacity: ${(props) => (props.disabled && !props.invalid ? ".5" : "1")};
  outline: none;
  padding: ${(props) =>
    props.hasButton ? "16px 52px 16px 16px" : "16px 16px"};
  padding-left: ${(props) => (props.withIcon ? "44px" : "16px")};
  width: 100%;
  -webkit-appearance: none;

  &:disabled {
    background: #fff;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    & {
      font-size: 16px;
    }
  }
`;

Input.defaultProps = {
  theme: defaultTheme,
};

export default Input;
