import { useEffect } from "react";

const validDomainList = [
  "http://localhost:3000",
  "https://stalwart-starlight-bfc7df.netlify.app",
  "https://app.aura.dental",
  "https://tapnote.zmooth.io",
  "https://tapnote2.zmooth.io",
];

const useMessageEventListener = (onNewMessage) => {
  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { typeMessage, ...data } = event.data || {};

      if (!validDomainList.includes(event.origin)) return;
      if (!typeMessage) return;

      onNewMessage({
        type: typeMessage,
        payload: data,
      });
    });
  }, [onNewMessage]);
};

export default useMessageEventListener;
