import { useContext } from "react";
import { Context } from "./appProvider";

export default function useAppStore() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useContext must be used within a Provider");
  }
  return context;
}
