import styled from 'styled-components';

const OptionsStepContainer = styled.div`
  padding: 5px 10px 0px;
  & > p {
    font-size: 16px;
    margin: 10px 0px 5px 0px;
    color: #6a6a6a;
  }
`;

export default OptionsStepContainer;
