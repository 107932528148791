import styled from "styled-components";
import { scale } from "../../common/animations";
import defaultTheme from "../../theme";

const Bubble = styled.div`
  animation: ${scale} ${(props) => (props.user ? "0s" : "0.3s")} ease forwards;
  background: ${(props) =>
    props.user ? props.theme.userBubbleColor : props.theme.botBubbleColor};
  border-radius: ${(props) => {
    const { isFirst, isLast, user } = props;

    if (!isFirst && !isLast) {
      return user ? "8px 0 0 8px" : "0 8px 8px 0px";
    }

    if (!isFirst && isLast) {
      return user ? "8px 0 8px 8px" : "0 8px 8px 8px";
    }

    return props.user ? "8px 8px 0 8px" : "8px 8px 8px 0";
  }};
  border: ${(props) =>
    props.user ? props.theme.userBorder : props.theme.botBorder};
  color: ${(props) =>
    props.user ? props.theme.userFontColor : props.theme.botFontColor};
  display: inline-block;
  font-size: 14px;
  max-width: 50%;
  margin: ${(props) => {
    const { isFirst, showAvatar, user } = props;

    if (!isFirst && showAvatar) {
      return user ? "0px 46px 0px 0" : "0px 0 0px 46px";
    }

    if (!isFirst && !showAvatar) {
      return user ? "0px 0px 0px 0" : "2px 0px 0px 0px";
    }

    return props.user ? "0px" : "2px 0px 0px 0px";
  }};
  overflow: hidden;
  word-break: break-word;
  position: relative;
  padding: 12px;
  transform: scale(0);
  transform-origin: ${(props) => {
    const { isFirst, user } = props;

    if (isFirst) {
      return user ? "bottom right" : "bottom left";
    }

    return user ? "top right" : "top left";
  }};

  a {
    color: #4755e7;
    word-wrap: break-word;
  }
`;

Bubble.defaultProps = {
  theme: defaultTheme,
};

export default Bubble;
