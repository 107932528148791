import ChatbotWrapper from "components/ChatbotWrapper";
import { AppProviderComponent } from "context";

const App = () => {
  return (
    <AppProviderComponent>
      <ChatbotWrapper />
    </AppProviderComponent>
  );
};

export default App;
