export const NODE_TYPES_NAME = {
  TEXT_STEP: "text",
  OPTIONS_STEP: "options",
  INITIAL_STEP: "initial",
  ENTRY_FIELD_STEP: "inputTextField",
};

export const INPUT_TYPES = {
  TEXT: "text",
  CONTACT_NUMBER: "contact_number",
  EMAIL: "email",
  FULL_NAME: "full_name",
};

export const ENQUIRY_TYPES = {
  options: {
    normal: "BASIC_OPTION",
    enquiry_reason: "MAIN_ENQUIRY",
  },
  inputTextField: {
    text: "TEXT",
    contact_number: "PHONE",
    email: "EMAIL",
    full_name: "NAME",
  },
};
