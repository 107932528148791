import styled from "styled-components";

const ChangeButton = styled.button`
  background: none;
  border: none;
  color: red;
  font-size: 14px;
  cursor: pointer;
  margin: 4px 0px 12px 0px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export default ChangeButton;
